
.adminLogin{
    width: 70%;
    margin: 0 auto;
    margin-top: 5%;
}

.adminForm{
    width: 300px;
    margin: 0 auto;
    margin-top: 5%;
}

.btnLog{
    margin-left: 5px;
}