body{
    color: white
}

.homeTable{
    margin: 5%;
    margin-top: 1%;
    margin-left: 15%;
    margin-right: 15%;
}

.dpicker{
    margin-top: 7%;
    margin-bottom: 7%;
}

.sbtn{
    margin-bottom: 10%;
}

@media only screen and (min-width: 600px) {
    .sbtn{
        display: flex;
        align-items: center;
        margin-top: 2%;
        margin-bottom: 2%;
    }
    .dpicker{
        margin-top: 2%;
        margin-bottom: 2%;
    }
  }

.table-bordered > tbody > tr > th {
    border: 1px solid;
}

.table-bordered > tbody > tr > td {
    border: 1px solid;
}

.navbar{
    background-color: #28a745;
    color: white;
}

.brand{
    color: white;
    margin: 2px;
    font-size: 25px;
}

.customHead{
    background: #28a745;
    border: 1px solid #28a745;
    color: white;
}

.nav-link{
    color: white;
}

.heading{
    padding: 10px;
    color: white;
    background-image: linear-gradient(to right, #ef5350 , #FFEE58)
}

.search{
    margin-right: 5%;
}